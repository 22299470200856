import {formatDate, Location} from '@angular/common';
import {AfterViewInit, Component, Directive, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import * as L from 'leaflet';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.js';
import 'TileLayer.Grayscale.js';
import {Observable} from 'rxjs';
import {debounceTime, finalize, switchMap, tap, map, startWith} from 'rxjs/operators';
import {Gefunden} from './_models/gefunden';
import { DbService } from './_services/db.service';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import {Fisch} from './_models/fisch';
import {Markierer} from './_models/markierer';
import {Projekt} from './_models/projekt';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import 'leaflet.legend.js';
import 'leaflet.legend.css';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // tslint:disable-next-line:object-literal-sort-keys
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private service: DbService,
    public screenOrientation: ScreenOrientation) { }
  senderControl = new FormControl();
  public isLoading = false;

  public gefunden: Gefunden[] = [];
  public finding = [];
  public fisch: Fisch;
  public filteredCode: Fisch[] = [];
  public markierer: Markierer[] = [];
  public projekte: Projekt[] = [];
  public map;
  public layers;
  public layerprojekte;
  public layergefunden;
  public layerfisch;
  public fishIcon;
  public fish2Icon;
  public fish3Icon;
  public markIcon;
  public projectIcon;
  public fish;
  public code: boolean;
  public details: string;
  public details2: string;
  public details3: string;
  public isMobileResolution = false;

  // tslint:disable-next-line:typedef
  public ngOnInit() {

    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
      document.getElementById('bgimg').style.width = '100%';
      document.getElementById('bgimg').style.height = '100%';
      document.getElementById('bgimg').style.left = '0%';
      this.changeOrientation();
      this.screenOrientation.onChange().subscribe(
        () => {
          this.changeOrientation();
        }
      );
    } else {
      this.isMobileResolution = false;
    }
/*
    this.service.getAll('markierers')
    .subscribe(markierer => this.markierer = markierer);
    this.service.getAll('gefunden')
      .subscribe(gefunden => this.gefunden = gefunden);
    this.service.getAll('projekte')
      .subscribe(projekte => this.projekte = projekte);
*/
    this.fisch = this.fish = null;
    this.details = 'Bitte klicken Sie einen Marker an, um weitere Informationen zu erhalten';

    this.fishIcon = L.icon({
      iconUrl: 'FishTrek.png',
      shadowUrl: 'schatten.png',

      iconSize:     [50, 50], // size of the icon
      shadowSize:   [2, 2], // size of the shadow
      iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.markIcon = L.ExtraMarkers.icon({
      icon: 'fa-home',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#99d8c9',
      svgOpacity: 0.5,
      svg: true
    });

    this.fish2Icon = L.ExtraMarkers.icon({
      icon: 'fa-fish',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#fdbb84',
      svgOpacity: 0.5,
      svg: true
    });
    this.fish3Icon = L.ExtraMarkers.icon({
      icon: 'fa-fish',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#3690c0',
      svgOpacity: 0.5,
      svg: true
    });

    this.projectIcon = L.ExtraMarkers.icon({
      icon: 'fa-flag',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#4eb3d3',
      svgOpacity: 0.5,
      svg: true
    });
/*
    if (new URLSearchParams(window.location.search).get('sender')){
      // tslint:disable-next-line:radix
      this.service.getConcrete('sender', parseInt(new URLSearchParams(window.location.search).get('sender')))
        .subscribe(sender => {
          this.sender = sender;
          this.gefunden.sid = sender.id;
        });
    }else{
      this.sender = {id: 0, name: '' };
    }
*/
    const tiles2 = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 5,
      maxZoom: 19,
      // tslint:disable-next-line:object-literal-sort-keys
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    this.map = L.map('map', {
      center: [ 51, 12 ],
      zoom: 5,
      tap: false,
      layers: [tiles2]
    });

    this.layergefunden = L.geoJson();
    this.layerfisch = L.geoJson();

   // tiles2.addTo(this.map);
   // L.control.layers(baseMaps, overlayMaps).addTo(this.map);

    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      showMarker: false, // optional: true|false  - default true
      showPopup: false,
    });

    this.map.addControl(searchControl);

    this.layergefunden.addTo(this.map);
    this.layerfisch.addTo(this.map);


    L.control.Legend({
      position: 'bottomleft',
      title: 'Legende',
      legends: [{
        label: '  markiert',
        type: 'image',
        url: 'fish-besetzt.png',
        layers: [this.layerfisch],
        inactive: false,
      },
        {
          label: '  gefunden',
          type: 'image',
          url: 'fish-found.png',
          layers: [this.layergefunden],
          inactive: false,
        }]
    }).addTo(this.map);

    this.senderControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCode = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsCode(value).pipe(
              finalize(() => {
                this.isLoading = false;
              }),
            )
        )
      )
      .subscribe(
        data => this.filteredCode = data
      );
  }

  obsCode(value: string): Observable<Fisch[]> {
    return this.service.getAllFilter('fischbycode', 'code', value);
  }

  // tslint:disable-next-line:typedef
  displayFn(kunde: Fisch) {
    if (kunde) {
      return kunde.code; }
  }

  changeCode(layer: any): void {
    if (!this.map.hasLayer(layer)){
      layer.addTo(this.map);
    } else {
      this.map.removeLayer(layer);
    }
    this.details = 'Bitte klicken Sie einen Marker an, um weitere Informationen zu erhalten';
    this.details2 = this.details3 = '';
  }
/*
  changeFisch(): void {
    if (this.code) {
      this.gefunden.forEach(find => {
          const fischi = (L.marker([find.lat, find.lon], {icon: this.fish2Icon}));
          const time = find.datum.split(' ');
          const date2 = time[0].split('-');
          const datum2 = date2[2] + '.' + date2[1] + '.' + date2[0];
          this.map.addLayer(fischi.bindPopup(
            '<strong>Fundort:</strong><br>' + find.fort
            + '<br><br><strong>Datum:</strong><br>' + datum2 + '<br><br>'
            + '<strong>Länge:</strong><br>' + find.laenge + ' '
            + 'cm<br><br><strong>Gewicht:</strong><br>' + find.gewicht + ' g'
            + '<br><br><strong>Wanderzeit:</strong><br>' + find.zeit
            + '<br><br><strong>Wanderdistanz:</strong><br>' + find.distanz
            ));
          this.finding.push(fischi);
        });
      this.fish = L.marker([this.fisch.lat, this.fisch.lon], {icon: this.fish3Icon});
      this.details = '<strong>Fischart:</strong><br>' + this.fisch.fisch + '<br><br><strong>Sendertyp:</strong><br>' + this.fisch.sender;
      this.details2 = '<strong>Code:</strong><br>' + this.fisch.code + '<br><br><strong>Datum:</strong><br>' + this.fisch.datum;
      this.details3 = '<strong>Länge:</strong><br>' + this.fisch.laenge + ' ';
      this.details3 += 'cm<br><br><strong>Gewicht:</strong><br>' + this.fisch.gewicht + ' g';
      const date = this.fisch.datum.split('-');
      const datum = date[2] + '.' + date[1] + '.' + date[0];
      this.map.addLayer(this.fish.bindPopup(
          '<strong>Fischart:</strong><br>' + this.fisch.fisch
          + '<br><br><strong>Datum:</strong><br>' + datum + '<br><br>'
          + this.details3));
    } else {
      this.map.removeLayer(this.fish);
      this.finding.forEach(gefound => this.map.removeLayer(gefound));
      this.finding = [];
      this.details = 'Bitte klicken Sie einen Marker an, um weitere Informationen zu erhalten';
      this.details2 = this.details3 = '';
    }
  }
*/

  changeFisch(): void {
    this.layergefunden.clearLayers();
    this.layerfisch.clearLayers();
    this.gefunden.forEach(find => {
        const fischi = (L.marker([find.lat, find.lon], {icon: this.fish2Icon}));
        const time = find.datum.split(' ');
        const date2 = time[0].split('-');
        const datum2 = date2[2] + '.' + date2[1] + '.' + date2[0];
        this.layergefunden.addLayer(fischi.bindPopup(
          '<strong><h2>Wiederfang</h2></strong>'
          + '<table cellpadding="5" style="width: 100%;border-color: #9a9a9a" border="0"><tbody>'
          + '<tr><td><strong>Art: </strong></td><td> ' + this.fisch.fisch
          + '</td></tr><tr><td>' +
          '<strong>Fundort: &nbsp;</strong></td><td> ' + find.fort
          + '</td></tr><tr><td>' +
          '<strong>Gewässer: &nbsp;</strong></td><td> ' + find.fluss
          + '</td></tr><tr><td>' +
          '<strong>Wiederfangdatum: &nbsp;</strong></td><td> ' + datum2
          + '</td></tr><tr><td>' +
          '<strong>Länge: &nbsp;</strong></td><td> ' + find.laenge + ' cm'
          + '</td></tr><tr><td>' +
          '<strong>Gewicht: &nbsp;</strong></td><td> ' + find.gewicht + ' g'
          + '</td></tr><tr><td>' +
          '<strong>Reisedauer (Tage): &nbsp;</strong></td><td> ' + find.zeit
          + '</td></tr><tr><td>' +
          '<strong>Reisedistanz (km): &nbsp;</strong></td><td> ' + find.distanz
          + '</td></tr></tbody></table>'
        ));
      });
    this.fish = L.marker([this.fisch.lat, this.fisch.lon], {icon: this.fish3Icon});
    this.details = '<strong><h2>Markierung</h2></strong><br><strong>Art:</strong> ' + this.fisch.fisch +
      '<br><strong>Sendertyp:</strong> ' + this.fisch.sender;
    this.details2 = '<strong>Code:</strong> ' + this.fisch.code + '<br><strong>Datum:</strong> ' + this.fisch.datum;
    this.details3 = '<strong>Länge:</strong> ' + this.fisch.laenge + ' ';
    this.details3 += 'cm<br><br><strong>Gewicht:</strong> ' + this.fisch.gewicht + ' g';
    const date = this.fisch.datum.split('-');
    const datum = date[2] + '.' + date[1] + '.' + date[0];
    this.layerfisch.addLayer(this.fish.bindPopup(
        '<strong><h2>Markierung</h2></strong>'
      + '<table cellpadding="5" style="width: 100%;border-color: #9a9a9a" border="0"><tbody>'
      + '<tr><td><strong>Art: &nbsp; </strong></td><td> ' + this.fisch.fisch
      + '</td></tr><tr><td>' +
      '<strong>Markierer: &nbsp; </strong></td><td> ' + this.fisch.markierer
      + '</td></tr><tr><td>' +
      '<strong>Sendertyp: &nbsp; </strong></td><td> ' + this.fisch.sender
      + '</td></tr><tr><td>' +
      '<strong>Gewässer: &nbsp; </strong></td><td> ' + this.fisch.fluss
      + '</td></tr><tr valign="top"><td>' +
      '<strong>Projektname: &nbsp; </strong></td><td> ' + this.fisch.projektname
      + '</td></tr><tr valign="top"><td><strong>Besatzdatum:&nbsp;</strong></td><td>' + datum + '</td></tr><tr valign="top">' +
      '<td><strong>Länge: &nbsp; </strong></td><td>' + this.fisch.laenge + ' cm</td></tr>'
      + '<tr valign="top"><td><strong>Gewicht: &nbsp; </strong></td><td>' + this.fisch.gewicht + ' g</td></tr>'
      + '</tbody></table>'));
    }

  changeFund(): void {
    this.gefunden = [];
    this.service.getAllFilter('gefundenbycode', 'code', this.fisch.code).subscribe(found => {
        found.forEach(find => {
          this.gefunden.push(find);
        });
      });
  }

  changeOrientation(): void {
    if (this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY) {
      document.getElementById('map-frame').style.height = '80%';
      document.getElementById('map-frame').style.width = '90%';
      document.getElementById('map-frame').style.left = '5%';
      document.getElementById('map-frame').style.top = '35%';
    } else {
      document.getElementById('map-frame').style.height = '30%';
      document.getElementById('map-frame').style.width = '90%';
      document.getElementById('map-frame').style.left = '5%';
      document.getElementById('map-frame').style.top = '40%';
    }
  }

/*  this.service.getConcrete('fischbyid', id)
.subscribe(fisch => this.fisch = fisch);
  */

  ngAfterViewInit(): void {
  }

}
